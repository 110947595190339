import React from "react";
import styled, {css} from "styled-components";
import { StyledLink } from "../core/commonExports";

import { useMixpanel } from "../../context/MixpanelContext";
import { isBrowser } from "../../utils/browserUtils";
import checkOfficeHours from "../../utils/checkOfficeHours";

import { CustomButton } from "../Navbar";

import VerakLogo from "../../assets/images/logo_color.svg";
import ChevronDown from "../../assets/images/chevron_down.svg";

export default function Navbar({ active, addCallCTA=false }) {
  const mixpanel = useMixpanel();

  const [isNavActive, setIsNavActive] = React.useState(false);
  const [activeDropdown, setActiveDropdown] = React.useState({
    products: false,
    blogs: false,
    aboutus: false,
    businessinsproducts: false
  });

  const handleActiveDropdown = (item) => {
    setActiveDropdown((val) => {
      if (item === "products" && activeDropdown.products) {
        return { ...val, [item]: !val[item], businessinsproducts: false };
      }
      return { ...val, [item]: !val[item] };
    });
  };

  const handleNavbarDrop = () => {
    setIsNavActive((value) => !value);
  };

  React.useEffect(() => {
    const allNavLinks = document.querySelectorAll(".mx-nav-event");
    allNavLinks?.forEach((link) => {
      link.addEventListener("click", (event) => {
        mixpanel.track(`Clicked ${event.target.innerText} (IRDAI compliant website) - Nav Bar`);
      });
    });
  }, []); // eslint-disable-line

  const isAfterHours = !checkOfficeHours();
  const handleHotlineClick = () => {
    if (isBrowser) {
      window.location.href = "tel:+919076277062"
    }
  };

  const contactCTA = isAfterHours ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://wa.me/919769161630?text=Hey"
    >
      <CustomButton
        type="primary"
        label="Talk to Us"
        minWidth="160px"
      />
    </a>
  ) : (
    <CustomButton
      type="primary"
      label="Call Us"
      minWidth="160px"
      onClick={handleHotlineClick}
    />
  );

  return (
    <>
      <NavbarWrapper className="fixed-to-top ">
        <Wrapper>
          <StyledLink className="display-flex flex-direction-column" to="/welcome/">
            <Logo alt="Verak Logo" />
          </StyledLink>
          <NavItems>
            <NavList>
              <ListItem active={active === "products"}>
                Products
                <ul className="drop">
                  <li>
                    <StyledLink to="/welcome/fire-insurance" className={`mx-nav-event`}>
                        Business Property Insurance
                    </StyledLink>
                  </li>
                  <StyledLink to="/welcome/liability-insurance" className="mx-nav-event">
                    <li>Liability Insurance</li>
                  </StyledLink>
                </ul>
              </ListItem>
              <ListItem>
                Blogs
                <ul className="drop">
                  <Link
                    href="https://insights.verak.in/"
                    className="mx-nav-event"
                  >
                    <li>Verak Insights</li>
                  </Link>
                  <Link href="https://blog.verak.in/" className="mx-nav-event">
                    <li>Verak Blog</li>
                  </Link>
                </ul>
              </ListItem>
              <ListItem active={active === "aboutus"} className="mx-nav-event">
                <StyledLink to="/welcome/aboutus">About Us</StyledLink>
              </ListItem>
              <ListItem 
                active={active === "powerusers"} 
                className="mx-nav-event" 
                style={addCallCTA ? { marginRight: '1rem' } : {}}
              >
                <StyledLink to="/powerusers">Power Users</StyledLink>
              </ListItem>
            </NavList>
            <Flex style={{ width: "fit-content" }} className="on-desktop">
              {addCallCTA ? contactCTA : null}
              <Burger onClick={handleNavbarDrop} active={isNavActive}>
                <div className="line1" />
                <div className="line2" />
                <div className="line3" />
              </Burger>
            </Flex>
          </NavItems>
        </Wrapper>
      </NavbarWrapper>

      <MobileNavDropdown active={isNavActive}>
        <DropdownItem>
          <DropdownItemHeader
            onClick={() => handleActiveDropdown("products")}
          >
            Products
            <ChevronDown className="chevron-down" />
          </DropdownItemHeader>
          <ul className={activeDropdown["products"] ? "dropped-down" : ""}>
            <li style={{paddingRight: "0", paddingBottom: "0"}}>
              <StyledLink to="/welcome/fire-insurance" className={`mx-nav-event`}>
                Business Property Insurance
              </StyledLink>
            </li>
            <li>
              <StyledLink to="/welcome/liability-insurance" className={`mx-nav-event`}>
                Liability Insurance
              </StyledLink>
            </li>
          </ul>
        </DropdownItem>
        <DropdownItem>
          <StyledLink to="/welcome/aboutus" className={`mx-nav-event`}>
            About Us
          </StyledLink>
        </DropdownItem>
        <DropdownItem>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem 0"
            }}
            onClick={() => handleActiveDropdown("blogs")}
          >
            Blogs
            <ChevronDown className="chevron-down" />
          </div>
          <ul className={activeDropdown["blogs"] ? "dropped-down" : ""}>
            <li>
              <a
                href="https://insights.verak.in/"
                style={{ paddingBottom: "0px" }}
                className={`mx-nav-event`}
              >
                Verak Insights
              </a>
            </li>
            <li>
              <a href="https://blog.verak.in/" className={`mx-nav-event`}>
                Verak Blog
              </a>
            </li>
          </ul>
        </DropdownItem>
        <DropdownItem>
          <StyledLink to="/powerusers" className={`mx-nav-event`}>
            Power Users
          </StyledLink>
        </DropdownItem>
      </MobileNavDropdown>
      <NavBackground active={isNavActive} />
    </>
  );
}

const Burger = styled.div`
  display: none;
  margin-left: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: ${({ active }) => `${active ? "rotate(-90deg)" : ""}`};

  div {
    width: 25px;
    height: 4px;
    margin: 5px;
    background-color: ${({ active }) => `${active ? "#FF934F" : "#4c4c4c"}`};
  }

  @media screen and (max-width: 1000px) {
    display: block;
  }
`;

const NavList = styled.ul`
  --gap-hi-offset: 0px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: calc(36px + var(--gap-hi-offset));
  @media screen and (max-width: 1350px) {
    gap: calc(28px + var(--gap-hi-offset));
  }
  @media screen and (max-width: 1150px) {
    gap: calc(20px + var(--gap-hi-offset));
  }
  @media screen and (max-width: 1080px) {
    gap: calc(12px + var(--gap-hi-offset));
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
`;

const NavItems = styled(Flex)`
  --margin-left-hi-offset: 0px;
  justify-content: flex-end;
  margin-left: calc(105px + var(--margin-left-hi-offset));
  @media (max-width: 1435px) {
    margin-left: calc(75px + var(--margin-left-hi-offset));
  }
  @media (max-width: 1350px) {
    margin-left: calc(30px + var(--margin-left-hi-offset));
  }
  @media (max-width: 1250px) {
    margin-left: calc(0px + var(--margin-left-hi-offset));
  }
  @media (max-width: 1150px) {
    margin-left: 0px;
  }
  @media (max-width: 1080px) {
    margin-left: 0px;
  }
  @media (max-width: 1000px) {
    justify-content: flex-end;
    margin-left: 0;
  }
`

const Link = styled.a`
  color: inherit;
`;

const ListItemDropdown = css`
  &:hover .drop,
  &:focus .drop {
    opacity: 1;
    visibility: visible;
  }

  .drop-with-children:hover .drop-child,
  .drop-with-children:hover .drop-child {
    opacity: 1;
    visibility: visible;
  }

  &:hover,
  &:focus {
    color: #f45e00;
  }

  .drop-child,
  .drop {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 4.5rem;
    left: -4rem;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 5px;
    width: 15rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    color: #333333;
    transition: opacity 200ms ease-in-out;
    height: fit-content;
    z-index: 9999;
    transform: translateY(-15px);

    &:after {
      content: "";
      position: absolute;
      top: -7px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid white;
    }

    &:hover,
    &:focus {
      opacity: 1;
      visibility: visible;
    }

    a {
      border-bottom: 0.5px solid #ececec;

      &:last-child {
        border-bottom: none;
      }
    }

    li {
      list-style: none;
      padding: 10px 12px;
      border-radius: 5px;
      font-size: 14px;
      transition: background-color 200ms ease-in-out;

      &:hover,
      &:focus {
        color: #f45e00;
      }
    }
  }

  .drop-child {
    left: calc(100% - 1rem);
    top: 1rem;
    right: -2rem;

    &:after {
      content: "";
      position: absolute;
      right: auto;
      left: -7px;
      top: 1.5rem;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-left: none;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid white;
    }
  }
`

const DropdownItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 0.5px solid #cccccc;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`

const ListItem = styled.li`
  list-style: none;
  position: relative;
  padding: 1.5rem 0.25rem;
  cursor: pointer;
  font-size: 16px;
  color: ${({ active }) => `${active ? "#f45e00" : "#333333"}`};
  font-weight: 500;
  ${ListItemDropdown};
`;

const Logo = styled(VerakLogo)`
  height: 28px;
  width: 134px;

  @media screen and (max-width: 768px) {
    width: 120px;
  }
`;

const Wrapper = styled(Flex)`
  height: 100%;
  width: 100%;
  max-width: 1440px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 80px;

  @media screen and (max-width: 768px) {
    padding: 0px 14px;
  }

  li {
    line-height: 20px;
  }
`;

const NavbarWrapper = styled.div`
  height: 80px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
`;

const DropdownItem = styled.div`
  font-size: 18px;
  color: #4c4c4c;
  font-weight: bold;
  display: flex;
  justify-content: center;
  border-bottom: 0.5px solid #cccccc;
  flex-direction: column;

  a {
    color: inherit;
    text-decoration: none;
    padding: 16px 0px;
    margin: 0 !important;
  }

  .chevron-down {
    transform: rotate(180deg);
  }

  ul {
    visibility: hidden;
    height: 0px;
  }

  .dropped-down {
    height: fit-content;
    visibility: visible;
  }

  li {
    padding: 10px;
    padding-top: 0px;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;

    .navbar-drop-icon {
      width: 34px;
      margin-right: 15px;
    }
  }
`;

const MobileNavDropdown = styled.div`
  display: none;
  transition: transform 0.3s ease-in;

  @media screen and (max-width: 1000px) {
    display: block;
    background-color: #fff;
    padding: 0px 16px;
    position: fixed;
    z-index: 1020;
    top: 80px;
    height: fit-content;
    width: 100vw;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    transform: ${({ active }) =>
      `${active ? "translateY(0%)" : "translateY(-100%)"}`};

    .social-icons {
      margin: 0px 15px;
    }
  }
`;

const NavBackground = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: ${({ active }) => `${active ? "block" : "none"}`};
    position: fixed;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    top: 0;
    left: 0;
    transition: opacity 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: ${({ active }) => `${active ? "1" : "0"}`};
  }
`;
