import React from "react";
import styled from "styled-components";
import { isBrowser } from "../utils/browserUtils";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import { Text } from "../components/core/commonExports";
import { OnDesktop, OnMobile } from "../components/DesktopMobileComps";
import { MetaComponent } from "../components";
import Navbar from "../components/placeholder-website/Navbar";
import { Footer } from "../components/Footer";

const AGENT_DATA = [
  {
    id: "168",
    name: "Roshan Sutar",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/roshan.png"
      />
    ),
  },
  {
    id: "176",
    name: "Umesh Bharti",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/umesh.png"
      />
    ),
  },
  {
    id: "181",
    name: "Sanjay Kharvar",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/sanjay.png"
      />
    ),
  },
  {
    id: "182",
    name: "Ankit Chalke",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/ankit.png"
      />
    ),
  },
  {
    id: "183",
    name: "Shailendra Shinde",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/shailendra.png"
      />
    ),
  },
  {
    id: "FOS15",
    name: "Pradeep Baviskar",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/pradeep.png"
      />
    ),
  },
  {
    id: "FOS16",
    name: "Shivam Prajapati",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/shivam.png"
      />
    ),
  },
  {
    id: "FOS17",
    name: "Sagar Kuril",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/sagar.png"
      />
    ),
  },
  {
    id: "FOS20",
    name: "Aniket Khot",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/aniketkhot.png"
      />
    ),
  },
  {
    id: "FOS21",
    name: "Aniket Lande",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/aniketlande.png"
      />
    ),
  },
  {
    id: "FOS22",
    name: "Vaibhav Kshirsagar",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/vaibhav.png"
      />
    ),
  },
  {
    id: "FOS23",
    name: "Prashant Kesare",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/prashant.png"
      />
    ),
  },
  {
    id: "FOS24",
    name: "Avinash Chavan",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/avinashchavan.png"
      />
    ),
  },
  {
    id: "FOS25",
    name: "Akshay Pawar",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/akshay.png"
      />
    ),
  },
  {
    id: "FOS26",
    name: "Rohit Budhkar",
    image: (
      <StaticImage
        alt="fos-agent"
        src="https://verak-assets.s3.ap-south-1.amazonaws.com/rohit.png"
      />
    ),
  },
];

const FOS = () => {
  const getUID = () => {
    if (isBrowser) {
      const queryParams = window.location.search;
      let id = "";
      if (queryParams) {
        id = queryParams.split("=")[1];
      } else {
        // Case of no ID
        window.location.replace("/");
      }
      if (id) {
        const data = AGENT_DATA.find((x) => x.id === id);
        if (typeof data !== "undefined") {
          return data;
        } else {
          // Case of invalid ID
          window.location.replace("/");
        }
      }
    }
  };
  const agentData = getUID();

  if (isBrowser) {
    localStorage.setItem("access", true);
  }

  return (
    <>
      <MetaComponent title="Welcome to Verak" loadYellowAiScript={false} />
      <Navbar addCallCTA={true} />
      <FormBg>
        <MainContainer>
          <OnMobile>
            <div style={{ width: "240px", height: "240px", margin: "0 auto" }}>
              {agentData?.image}
            </div>
          </OnMobile>
          <OnDesktop>{agentData?.image}</OnDesktop>
          <AgentContainer>
            <Text
              mfontSize="20px"
              mobileStyles={{ margin: "1.5rem 0", letterSpacing: "0.02em" }}
              color="#333333"
              fontWeight="bold"
              fontSize="30px"
              desktopStyles={{ margin: "1.5rem 0", letterSpacing: "0.02em" }}
            >
              {agentData?.name}
            </Text>
            <InfoContainer>
              <Row ques="Employee No:" ans={agentData?.id} />
              <Row ques="Status:" ans="Active" />
              <Row ques="Manager:" ans="Amruta Supekar" />
              <Row ques="Manager Contact:" ans="+91 9928484665" />
              <Row ques="Escalation Email ID:" ans="admin@verak.in" />
            </InfoContainer>
          </AgentContainer>
        </MainContainer>
      </FormBg>
      <Footer useNewAddress={true} />
    </>
  );
};

export default FOS;

export const query = graphql`
  query {
    locales: allLocale(
      filter: { ns: { in: ["footer"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Row = ({ ques, ans }) => {
  return (
    <div style={{ display: "flex" }}>
      <Text
        mfontSize="12px"
        mobileStyles={{ width: "min(90px, 100%)", letterSpacing: "0.02em" }}
        desktopStyles={{ width: "min(175px, 100%)", letterSpacing: "0.02em" }}
        color="#8a7e7e"
      >
        {ques}
      </Text>
      <Text
        mfontSize="12px"
        fontWeight="600"
        desktopStyles={{ margin: "10px 1.5rem" }}
      >
        {ans}
      </Text>
    </div>
  );
};

const FormBg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fcebe3;
`;

const MainContainer = styled.div`
  display: flex;
  margin: 8rem 0 4rem 0;
  text-align: center;
  @media (max-width: 768px) {
    margin: 8rem auto 4rem auto;
    flex-direction: column;
  }
`;

const AgentContainer = styled.div`
  @media (min-width: 768px) {
    margin-left: 7rem;
  }
`;

const InfoContainer = styled.div`
  width: 460px;
  padding: 20px;
  border: 1px solid #f7d1b9;
  border-radius: 7px;
  text-align: left;
  @media (max-width: 768px) {
    width: 287px;
    padding: 10px 20px;
  }
`;
